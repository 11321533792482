<template>
   <div class="container">
      <div class="referral-main">
         <div class="row justify-content-md-center">
            <div class="col-12 col-lg-8 mt-3 mb-5">

               <h4 class="text-center mb-4">Template Item</h4>
               <select v-model="currentTemplateId" @change="setCurrentTemplate">
                  <option v-for="(template, id) in templates" v-bind:key="id" v-bind:value="id">
                     {{template.name}}
                  </option>
               </select>

               <hr/>

               <h4>Instructions</h4>
               <textarea style="width: 100%" v-model="currentTemplate.instructions" />

               <h4>Value</h4>
               <textarea style="width: 100%" v-model="currentTemplate.value" />

            </div>
            <div>
               <p>Settings are not saved until you click this button. Use with caution!</p>
               <button @click="saveAll">Save All</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Referral",
   data() {
      return {
         templates: {},
         currentTemplateId: "",
         currentTemplate: {
            id: "",
            name: "(N/A)",
            instructions: "...",
            value: "",
         },
      }
   },
   computed: {
   },
   mounted() {
      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            this.$store.state.accountData = response.data;
            this.templates = response.data.frontend_settings;
            console.log(this.templates);

            if (!this.templates.referral_toggle) {
               this.templates["referral_toggle"] = {
                  id: "referral_toggle",
                  name: "Referral Feature Toggle",
                  instructions: "Enter true to show referral feature, false to hide it.",
                  value: "true",
               };
            }
            if (!this.templates.referral_redirect_url) {
               this.templates["referral_redirect_url"] = {
                  id: "referral_redirect_url",
                  name: "Referral Redirect URL",
                  instructions: "Enter /referral for normal app referral URL, or else fully qualified https://... link.",
                  value: "/referral",
               };
            }
            if (!this.templates.admin_base_url) {
               this.templates["admin_base_url"] = {
                  id: "admin_base_url",
                  name: "Admin Base URL",
                  instructions: "Enter / for normal use of admin features on this site, or else fully qualified https://... link.",
                  value: "/",
               };
            }
            if (!this.templates.twitter_promotion_hashtags) {
               this.templates["twitter_promotion_hashtags"] = {
                  id: "twitter_promotion_hashtags",
                  name: "Twitter Promotion Hashtags",
                  instructions: "Enter #hashtags,#separated,#by,#commas",
                  value: "#Xecrets,#Crypto,@cryptowallet,#SecureYourSeedPhrases,#notyourkeysnotyourcoins",
               };
            }
            if (!this.templates.twitter_promotion_thanks_dm) {
               this.templates["twitter_promotion_thanks_dm"] = {
                  id: "twitter_promotion_thanks_dm",
                  name: "Twitter Promotion Thanks DM",
                  instructions: "Use $PromoCode$ for the gifted promo code",
                  value: "Thanks for sharing @XECRETio - here is your free 5 slice credit: $PromoCode$",
               };
            }
            if (!this.templates.twitter_promotion_thanks_dm_demo) {
               this.templates["twitter_promotion_thanks_dm_demo"] = {
                  id: "twitter_promotion_thanks_dm",
                  name: "Twitter Promotion DEMO Thanks DM",
                  instructions: "Use $PromoCode$ for the gifted promo code",
                  value: "Thanks for sharing @XECRETio - here is a promo code to purchase a 3 slice credit at 50 percent off: $PromoCode$",
               };
            }
            if (!this.templates.tweet_reward_delay_hours) {
               this.templates["tweet_reward_delay_hours"] = {
                  id: "tweet_reward_delay_hours",
                  name: "Tweet Reward Delay Hours",
                  instructions: "Example: 0.5 means 30 minutes",
                  value: "0.0333",
               };
            }
            if (!this.templates.tweet_reward_max_times) {
               this.templates["tweet_reward_max_times"] = {
                  id: "tweet_reward_max_times",
                  name: "Tweet Reward Max Times",
                  instructions: "Example: 5 for five influencer tweets rewarded",
                  value: "5",
               };
            }
            if (!this.templates.congrats_tweet_verified_html) {
               this.templates["congrats_tweet_verified_html"] = {
                  id: "congrats_tweet_verified_html",
                  name: "Congrats Tweet HTML (Verified)",
                  instructions: "Enter HTML content including <tags>. Use $AppDomain$ as a placeholder for app domain name, EXCLUDING any https:// prefix or slashes - for example, may be expanded to xecret.io or testxecretio.testdeployments.com",
                  value: "Xecret.io is committed to helping the web3 community grow.",
               };
            }
            if (!this.templates.congrats_tweet_unverified_html) {
               this.templates["congrats_tweet_unverified_html"] = {
                  id: "congrats_tweet_unverified_html",
                  name: "Congrats Tweet HTML (Unverified)",
                  instructions: "Enter HTML content including <tags>. Use $AppDomain$ as a placeholder for app domain name, EXCLUDING any https:// prefix or slashes - for example, may be expanded to xecret.io or testxecretio.testdeployments.com",
                  value: "Xecret.io is committed to helping the web3 community grow.",
               };
            }
            if (!this.templates.demo_congrats_tweet_verified_html) {
               this.templates["demo_congrats_tweet_verified_html"] = {
                  id: "demo_congrats_tweet_verified_html",
                  name: "Demo Congrats Tweet HTML (Verified)",
                  instructions: "Enter HTML content including <tags>. Use $AppDomain$ as a placeholder for app domain name, EXCLUDING any https:// prefix or slashes - for example, may be expanded to xecret.io or testxecretio.testdeployments.com",
                  value: "Xecret.io is committed to helping the web3 community grow.",
               };
            }
            if (!this.templates.demo_congrats_tweet_unverified_html) {
               this.templates["demo_congrats_tweet_unverified_html"] = {
                  id: "demo_congrats_tweet_unverified_html",
                  name: "Demo Congrats Tweet HTML (Unverified)",
                  instructions: "Enter HTML content including <tags>. Use $AppDomain$ as a placeholder for app domain name, EXCLUDING any https:// prefix or slashes - for example, may be expanded to xecret.io or testxecretio.testdeployments.com",
                  value: "Xecret.io is committed to helping the web3 community grow.",
               };
            }
            if (!this.templates.congrats_tweeturl) {
               this.templates["congrats_tweeturl"] = {
                  id: "congrats_tweeturl",
                  name: "Congrats Tweet URL",
                  instructions: "Enter a URL to be opened by the tweet button",
                  value: "https://twitter.com/intent/tweet?hashtags=SecureYourSeedPhrases%2C&original_referer=https%3A%2F%2Fxecret.io%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Ehashtag%7Ctwgr%5ESecureYourSeedPhrases&text=Use%20Xecrets%20to%20secure%20your%20seed%20phrases.%20%23Xecrets%20%23Crypto%20%23coldstorage%20%23SeedPhrases",
               };
            }
            if (!this.templates.paid_tweet_rewards_toggle) {
               this.templates["paid_tweet_rewards_toggle"] = {
                  id: "paid_tweet_rewards_toggle",
                  name: "Paid Tweet Rewards Toggle",
                  instructions: "Enter true to enable rewards for paid (normal app, purchased credit) tweet rewards, or false to deactivate the feature.",
                  value: "true",
               };
            }
            if (!this.templates.demo_tweet_rewards_toggle) {
               this.templates["demo_tweet_rewards_toggle"] = {
                  id: "demo_tweet_rewards_toggle",
                  name: "Demo Tweet Rewards Toggle",
                  instructions: "Enter true to enable rewards for free demo app tweet rewards, or false to deactivate the feature.",
                  value: "true",
               };
            }
            if (!this.templates.influencer_tweet_rewards_toggle) {
               this.templates["influencer_tweet_rewards_toggle"] = {
                  id: "influencer_tweet_rewards_toggle",
                  name: "Influencer Tweet Rewards Toggle",
                  instructions: "Enter true to enable rewards for influencer tweet rewards (i.e. not tied to congrats page), or false to deactivate the feature.",
                  value: "true",
               };
            }
            console.log("\n\n\n\n\n\n\n\nTemplates:");
            console.log(this.templates);
         })
         .catch((error) => console.error(error));
   },
   methods: {
      saveAll() {
         let formData = {
            code: "frontend",
            value: this.templates,
         };
        this.axios.post("/api/admin-update-setting", formData, { });
      },
      setCurrentTemplate() {
         this.currentTemplate = this.templates[this.currentTemplateId];
         console.log(this.currentTemplate);
      }
   }
}
</script>
